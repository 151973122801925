import React from 'react'

import clsx from 'clsx'
import { useChatStore } from '../../chatCommunParts/chat-store'
import { getOrMakeDecorationStyles } from './Mobile.styles'
import { Typography } from '@mui/material'
import { TextsProvider } from '../texts'
import SearchIcon from '@mui/icons-material/Search'

// :: Component(ChatDecorationView)

const texts = TextsProvider.get()

export type ChatDecorationProps = {
    className?: string
}

export function ChatDecorationView({ className }: ChatDecorationProps) {
    const scope = useChatStore((store) => store.decoration)

    const { classes } = getOrMakeDecorationStyles()

    return !scope ? (
        <></>
    ) : (
        <header className={clsx(classes.header, className)}>
            <Typography fontSize={16}>{texts.CHATS_TITLE}</Typography>
            <span className={classes.hSpacer} />
            <SearchIcon />
        </header>
    )
}
