import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { CircularProgress } from '@mui/material'
import { FlagMessageDTO, SyoWhatsAppChatCreationOrigin, handleSyoWhatsAppBaseUrl } from '@whatsapp/communication'
import React, { useEffect, useRef } from 'react'
import { useApplicationStore } from 'src/aplication-store'
import { EurekaService } from 'src/utils/services'
import { TextsProvider } from '../texts'
import { WhatsAppScope } from '../WhatsApp.scopes'
import { getOrMakeWhatsAppStyles as getOrMakeStyles } from './WhatsApp.styles'

// @Inject
const texts = TextsProvider.get()

// @Inject
const eurekaService = EurekaService.singleton()

export type WhatsAppViewProps = {
    className: string
    scope: WhatsAppScope
}

export function WhatsAppView({ className, scope }: WhatsAppViewProps) {
    const syoWhatsAppRef = useRef<HTMLElement>(null)
    const [attendantName, language, user] = useApplicationStore((store) => [
        store.attendantName,
        store.language,
        store.user
    ])
    const { classes: styles, cx } = getOrMakeStyles()
    const { expanded, fullPage, onMessageSent } = useApplicationStore((store) => {
        return { expanded: store.expanded, fullPage: store.fullPage, onMessageSent: store.onMessageSent }
    })

    const onMessageSentRef = useRef(onMessageSent)
    onMessageSentRef.current = onMessageSent

    useEffect(() => {
        if (scope.loading || scope.error) return

        const syoWhatsApp = syoWhatsAppRef.current

        if (syoWhatsApp) {
            const handleMessageSent = (evt: Event) => {
                const customEvent = evt as CustomEvent<FlagMessageDTO>
                onMessageSentRef.current(customEvent)
            }

            syoWhatsApp.addEventListener('message-sent', handleMessageSent)

            return () => {
                syoWhatsApp.removeEventListener('message-sent', handleMessageSent)
            }
        }
    }, [scope.loading, scope.error])

    if (!expanded && !fullPage) {
        return null
    }

    return scope.loading ? (
        <div className={cx(styles.view, className)}>
            <div className={styles.loadingContainer}>
                <CircularProgress color="secondary" disableShrink />
                <div className={styles.loadingText}>{texts.LOADING_CONTENT_DESCRIPTION}</div>
            </div>
        </div>
    ) : scope.error ? (
        <div className={cx(styles.view, className)}>
            <div className={styles.errorContainer}>
                <ErrorOutlineRoundedIcon fontSize="large" color="error" />
                <div className={styles.errorText}>{texts.FAILED_DURING_LOAD_WHATSAPP_COMPONENT}</div>
            </div>
        </div>
    ) : (
        <div className={cx(styles.view, className)}>
            {React.createElement('syo-whats-app', {
                ref: syoWhatsAppRef,
                class: styles.syoWhatsApp,
                eureka: toPropString(scope.eureka, HAS_SYO_WHATS_APP_ERROR_ON_CONFIG()?.eureka ?? ''),
                customername: toPropString(scope.customerName, ''),
                customerphone: toPropString(scope.customerPhone, ''),
                companyphone: toPropString(scope.companyPhone, ''),
                companyid: toPropString(scope.companyId, ''),
                username: toPropString(user?.username, ''),
                room: toPropString(scope.room, ''),
                eventinfo: toPropString(scope.eventInfo, ''),
                templatefields: toPropString(scope.templateFields, ''),
                message: toPropString(scope.message, ''),
                sendhsm: toPropString(scope.sendHsm, 'false'),
                readonly: toPropString(scope.readOnly, 'false'),
                language: toPropString(language, 'PT-BR'),
                attendantName: attendantName,
                creationOrigin: SyoWhatsAppChatCreationOrigin.CHAT_BOAT
            })}
        </div>
    )
}

const getBaseUrl = () => {
    let url = localStorage.getItem('URL_CDN_WHATSAPP')
    if (!url) {
        url = handleSyoWhatsAppBaseUrl(window.location.hostname)
    }
    return url
}

const BASE_URL = getBaseUrl()

const HAS_SYO_WHATS_APP_ERROR_ON_CONFIG = function () {
    let cfg = {
        eureka: eurekaService.endpoint(),
        socket_io_worker_js: BASE_URL
    }

    const s = localStorage.getItem('syowhatsapp')
    if (s) {
        try {
            cfg = JSON.parse(s)
        } catch (caught) {
            console.error('Parsing syowhatsapp from LocalStorage', caught)
        }
    }

    return cfg
}

function toPropString(value: unknown, defaultValue: string) {
    if (value !== null && value !== undefined) {
        if (typeof value == 'object') {
            return JSON.stringify(value)
        }
        return String(value)
    } else if (defaultValue !== null && defaultValue !== undefined) {
        return defaultValue
    } else {
        return ''
    }
}
