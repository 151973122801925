import React from 'react'

import clsx from 'clsx'
import WarningIcon from '@mui/icons-material/Warning'

import { WhatsAppView } from 'src/components/whatsapp/views'
import { TextsProvider } from '../texts'
import { useChatStore } from '../../chatCommunParts/chat-store'

import { getOrMakeStyles } from './Chat.styles'
import { ChatContactsView } from './ChatContacts.view'
import { ChatHeaderView } from './ChatHeader.view'
import { ChatDecorationView } from './ChatDecoration.view'
import Typography from '@mui/material/Typography'
import { useApplicationStore } from 'src/aplication-store'
const texts = TextsProvider.get()

// :: Component(ChatView)

export type ChatViewProps = {
    className?: string
}

export function ChatView({ className }: ChatViewProps) {
    const scope = useChatStore()
    const [fullpage, loadingTickets] = useApplicationStore((store) => [store.fullPage, store.loadingTickets])

    const { classes } = getOrMakeStyles()
    // Using CubeRefObject in order to avoid refresh
    const drawerElRef = React.useRef(null)
    const [lazyContactsOpened, setLazyContactsOpended] = React.useState(scope.contactsOpened)

    const handleCloseContactList = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (event.target === drawerElRef.current) {
                scope.onCloseContactList()
            }
        },
        [scope, drawerElRef]
    )

    const handleDrawerAnimationEnded = React.useCallback(() => {
        if (scope.header) {
            setLazyContactsOpended(scope.contactsOpened)
        }
    }, [scope])

    let content: JSX.Element
    if (scope.itemCount === 0) {
        content = (
            <>
                {!fullpage && <ChatDecorationView />}
                {loadingTickets || scope.isLoading ? (
                    <div className={classes.noContentAlert}>
                        <WarningIcon className="icon" />
                        <div className="rows">
                            <Typography variant="subtitle2">{texts.LOADING_YOUR_CONVERSATIONS}</Typography>
                        </div>
                    </div>
                ) : (
                    <div className={classes.noContentAlert}>
                        <WarningIcon className="icon" />
                        <div className="rows">
                            <Typography variant="subtitle2">{texts.NO_CHATS_AVAILABLE}</Typography>
                        </div>
                    </div>
                )}
            </>
        )
    } else if (!fullpage) {
        const openCls =
            scope.contactsOpened || lazyContactsOpened
                ? scope.contactsOpened
                    ? classes.drawerOpened
                    : classes.drawerClosing
                : classes.drawerClosed

        const contextListCls = scope.contactsOpened ? classes.contactBarShowIn : classes.contactBarHideOut
        content = (
            <>
                <ChatDecorationView />
                <div className={classes.body}>
                    <div className={clsx(classes.drawer, openCls)} ref={drawerElRef} onClick={handleCloseContactList}>
                        <ChatContactsView className={contextListCls} onAnimationEnd={handleDrawerAnimationEnded} />
                    </div>
                    <ChatHeaderView />
                    <WhatsAppView className={classes.chatBody} scope={scope.whatsapp} />
                </div>
            </>
        )
    } else {
        content = (
            <div className={classes.chatContainer}>
                <div className={classes.contactContainer}>
                    <ChatContactsView onAnimationEnd={handleDrawerAnimationEnded} />
                </div>
                <div className={classes.body}>
                    <ChatHeaderView />
                    <WhatsAppView className={classes.chatBody} scope={scope.whatsapp} />
                </div>
            </div>
        )
    }

    return <div className={clsx(classes.view, className)}>{content}</div>
}
