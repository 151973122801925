import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()({
    contentView: {
        width: '100%'
        // marginLeft: -8
    },
    middleContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    primaryContent: {
        maxWidth: 492,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    secondaryContent: {
        whiteSpace: 'nowrap'
    },
    subtitleContent: {
        maxWidth: 220,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
})
