import React from 'react'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import TablePagination from '@mui/material/TablePagination'
import { useContainer } from 'src/utils/views/useContainer'
import { useChatStore } from '../../chatCommunParts/chat-store'
import { TextsProvider } from '../texts'

import { getOrMakeContactsStyles } from './Mobile.styles'
import { ChatContactItemView } from './MobileContactItem.view'

import { useApplicationStore } from 'src/aplication-store'
import { Divider } from '@mui/material'

const texts = TextsProvider.get()

// :: Component(ChatContactsView)

export type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

export type ChatContactsViewProps = {
    className?: string
    onAnimationEnd: (event: React.AnimationEvent<HTMLDivElement>) => void
}

export function ChatContactsView({ className, onAnimationEnd }: ChatContactsViewProps) {
    const scope = useChatStore()
    const [blockContactChange] = useApplicationStore(({ blockContactChange }) => [blockContactChange])

    const { classes, cx } = getOrMakeContactsStyles()
    const containerEl = useContainer()

    const itemCount = scope.itemCount || 0
    const itemsPerPage = scope.itemsPerPage || 0
    const pageIndex = scope.pageIndex
    const backNextIconDisplacement = scope.itemsPerPage < 100 ? -10 : -15

    const onChangePageIndex = scope.onChangePageIndex as unknown as PageChangeEventHandler
    const onChangeItemsPerPage = scope.onChangeItemsPerPage

    React.useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.block && !blockContactChange) {
                    setTimeout(() => useApplicationStore.getState().setBlockContactChange(false), 30000)
                }

                useApplicationStore.getState().setBlockContactChange(event.data.block)
            },
            false
        )
    }, [])

    return (
        <Box className={cx(classes.contactListView, className)} onAnimationEnd={onAnimationEnd}>
            {/* <Box p={2} display={'flex'} gap={2}>
                <Chip
                    onClick={() => scope.onFilterChange('ALL')}
                    color={scope.filter === 'ALL' ? 'primary' : 'default'}
                    label={texts.CHATS_ALL_CHATS}
                    sx={{ width: 'fit-content' }}
                />
                <Chip
                    onClick={() => scope.onFilterChange('UNREAD')}
                    color={scope.filter === 'UNREAD' ? 'primary' : 'default'}
                    label={texts.CHATS_UNREAD_CHATS}
                    sx={{ width: 'fit-content' }}
                />
            </Box> */}
            <List disablePadding>
                {scope.entries.map((contactScope, index) => (
                    <>
                        <ChatContactItemView
                            key={contactScope.id}
                            scope={{
                                ...contactScope,
                                disabled: blockContactChange,
                                ...(blockContactChange && { onSelect: () => false })
                            }}
                        />
                        {index < scope.entries.length - 1 && <Divider variant="inset" component="li" />}
                    </>
                ))}
            </List>
            <Box position={'static'} bottom={0} left={0} right={0} width={'100%'}>
                <TablePagination
                    rowsPerPageOptions={scope.itemsPerPageOptions}
                    component="div"
                    size="small"
                    count={itemCount}
                    rowsPerPage={itemsPerPage}
                    page={pageIndex}
                    onPageChange={onChangePageIndex}
                    onRowsPerPageChange={onChangeItemsPerPage}
                    labelRowsPerPage={texts.CHATS_PER_USER_ROWS_PER_PAGE}
                    SelectProps={{
                        MenuProps: {
                            container: containerEl
                        }
                    }}
                    backIconButtonProps={{
                        sx: { margin: 0, padding: 0, left: backNextIconDisplacement }
                    }}
                    nextIconButtonProps={{
                        sx: { margin: 0, padding: 0, left: backNextIconDisplacement }
                    }}
                    className={classes.customTablePagination}
                />
            </Box>
        </Box>
    )
}
