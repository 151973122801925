import React from 'react'
import { useApplicationStore } from './aplication-store'
import { ChatView } from './feature/chat/views'
import { MainView } from './feature/main/views'

export function App() {
    const [user, fullPage] = useApplicationStore(({ user, fullPage }) => [user, fullPage])

    return !user?.username ? <></> : fullPage ? <ChatView /> : <MainView />
}
