import { WhatsAppTemplateStatus } from '@infobip/api'
import { ActiveMessageButton, InteractionType, TicketGenerationOptions, WrapperMessage } from './infobip'
import { ChannelMenuType } from './bff/admin/ChannelConfiguration.communication'
import { DefaultVariable, Department } from './bff/adminV2'

export enum SocketChannel {
    CONNECTION = 'connection',
    DISCONNECT = 'disconnect',
    JOIN_ROOM = 'join_room',
    ENTER_ROOM = 'enter_room',
    SEND_MESSAGE = 'send_message',
    RECEIVE_MESSAGE = 'receive_message',
    UPDATE_MESSAGE = 'update_message',
    SEND_PARTNER_MESSAGE = 'send_partner_message',
    RECEIVE_PARTNER_MESSAGE = 'receive_partner_message',
    MORE_MESSAGE = 'more_message',
    READ_NOTIFY = 'read_notify',
    USER_READ_NOTIFY = 'USER_READ_NOTIFY',
    SEND_TEMPLATE = 'send_template',
    RECEIVE_ACTIVE_MESSAGE = 'receive_active_message',
    RECEIVE_SESSION = 'receive_session',
    UPDATE_CHAT_STATUS = 'update_chat_status'
}

export type ChannelTemplateInfo = Pick<TemplateMessage, 'id' | 'label' | 'interactive' | 'interactionType'> & {
    fields: string[]
}

export type MetaStatus = keyof typeof WhatsAppTemplateStatus

export type RequestSaveTemplates = {
    template: Partial<TemplateMessage>
    defaultVariables?: DefaultVariable[]
}
export interface TemplateMessage {
    id: string
    account: string
    language: string
    default: boolean
    originalTemplate: string
    customTemplate: string
    label: string
    key: string
    partner: string
    group: string
    channelPhone: string
    visible: boolean
    toReview: boolean
    reasonByReview: string
    editedAt: string
    createdAt: string
    templateType?: string
    mediaUrl?: string
    footer?: string
    interactive?: boolean
    questionCompany?: boolean
    interactionType?: InteractionType
    buttons?: ActiveMessageButton[]
    ticketGenerationOptions?: TicketGenerationOptions
    removed?: boolean
    hasAdditionalCharge?: boolean
    canCreateRoom?: boolean
    metaStatus?: MetaStatus
    errorMessage?: string
    department?: Department[]
    segmentation?: number
}

export type KnexTemplateMessages = {
    id: string
    account: string
    language: string
    template_default: boolean
    original_template: string
    custom_template: string
    label: string
    key: string
    partner: string
    template_group: string
    channel_phone: string
    visible: boolean
    to_review: boolean
    reason_by_review: string
    edited_at: string
    created_at: string
    template_type?: string
    media_url?: string
    interactive?: boolean
    question_company?: boolean
    interaction_type?: InteractionType
    buttons?: ActiveMessageButton[]
    ticket_generation_options?: TicketGenerationOptions
    removed?: boolean
    footer?: string
    has_additional_charge?: boolean
    can_create_room?: boolean
    meta_status?: MetaStatus
    error_message?: string
}

export type TemplateMessageDefaultValue = {
    id?: string
    account: string
    templateId: string
    key: string
    value: string
    channelPhone?: string
    editedAt?: string
    createdAt?: string
}

export type KnexTemplateMessageDefaultValue = {
    id?: string
    account: string
    template_id: string
    key: string
    value: string
    channel_phone?: string
    edited_at?: string
    created_at?: string
}

export type TemplateFields = {
    [key: string]: string | number
}

export type MonitorType = 'ROBOT_RESCUE' | 'EVENT_RESCUE'

export type EventRescueConfigurationType = {
    group: string
    type: string
    media: string
    origin: string
}

export type ButtonClickedType = 'BUTTON0' | 'BUTTON1' | 'BUTTON2' | 'BUTTON3'

export enum SyoWhatsAppChatCreationOrigin {
    CRM = 'crm',
    CRM_APP = 'crm_app',
    CHAT_BOAT = 'chat_boat',
    WHATSAPP_ADMIN_CHAT = 'whatsapp_admin_chat',
    WHATSAPP_ADMIN_V2_CHAT = 'whatsapp_admin_v2_chat'
}

export type ServiceDTO = {
    _id: string
    partnerId: string
    aiFlowId?: string
    companyId: string
    phone?: string
    group: string
    type: string
    code: string
    url: string
    key?: string
    listSubService?: string[]
    isSubService?: boolean
    selfSchedule?: boolean
    description?: string
    menuType?: ChannelMenuType
    menuMessage?: string
    menuUUID?: string
    positiveButtonMessage?: string
    negativeButtonMessage?: string
    segmentations?: string[]
    editedAt?: Date
    createdAt?: Date
}

export type FlagMessageDTO = {
    id: string
    account: string
    contact?: string
    ticketId: number
    idUsuario?: number
    customerReference: string
    hostReference: string
    origin: 'INSIDE' | 'OUTSIDE'
    stamp: string
    service: string
    sent?: boolean
    message?: string
    userNotification?: boolean
    isHsm?: boolean
}

export const getTextMessageFromWrapper = (wrapper: WrapperMessage) => {
    if (wrapper.message.type === 'TEXT') {
        return wrapper.message.text
    }
    if (wrapper.message.type === 'ACTIVE_MESSAGE') {
        return wrapper.message.preview.text
    }
    return ''
}
