import React from 'react'
import { useApplicationStore } from './aplication-store'
import { ChatView } from './feature/chat/views'
import { MobileView } from './feature/mobile/views'
import { MainView } from './feature/main/views'

export function App() {
    const [user, fullPage, isMobile] = useApplicationStore(({ user, fullPage, isMobile }) => [user, fullPage, isMobile])

    return !user?.username ? <></> : fullPage ? <ChatView /> : isMobile ? <MobileView /> : <MainView />
}
