import { createTheme as muiCreateTheme } from '@mui/material'
import { LocaleProvider } from './utils/views'

export type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

export const createEmotionStyleElement = () => {
    const sheet = document.createElement('style')
    sheet.innerHTML = [
        '*::-webkit-scrollbar { width: 0.4em; }',
        '*::-webkit-scrollbar-thumb { border-radius: 0.5rem; background-color: #43A047; }',
        '*::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%); }'
    ].join('\n')
    return sheet
}

export const getOrCreateTheme = (() => {
    let getTheme = () => {
        const locale = LocaleProvider.get()

        const theme = muiCreateTheme(
            {
                typography: {
                    h1: {
                        fontSize: 96
                    },
                    h2: {
                        fontSize: 60
                    },
                    h3: {
                        fontSize: 48
                    },
                    h4: {
                        fontSize: 34
                    },
                    h5: {
                        fontSize: 24
                    },
                    h6: {
                        fontSize: 20
                    },
                    subtitle1: {
                        fontSize: 16
                    },
                    subtitle2: {
                        fontSize: 14
                    },
                    body1: {
                        fontSize: 16
                    },
                    body2: {
                        fontSize: 14
                    },
                    caption: {
                        letterSpacing: '1px'
                    }
                },
                palette: {
                    primary: {
                        main: 'rgba(44, 53, 75, 1)',
                        dark: 'rgba(30, 37, 52, 1)',
                        light: 'rgba(86, 93, 111, 1)',
                        contrastText: 'rgba(255, 255, 255, 1)'
                    },
                    secondary: {
                        main: 'rgba(220, 34, 42, 1)',
                        dark: 'rgba(154, 23, 29, 1)',
                        light: 'rgba(227, 78, 84, 1)',
                        contrastText: 'rgba(255, 255, 255, 1)'
                    },
                    text: {
                        primary: 'rgba(0, 0, 0, 0.87)',
                        secondary: 'rgba(0, 0, 0, 0.6)',
                        disabled: 'rgba(0, 0, 0, 0.38)'
                    },
                    action: {
                        hover: 'rgba(0, 0, 0, 0.04)',
                        selected: 'rgba(0, 0, 0, 0.08)',
                        disabledBackground: 'rgba(0, 0, 0, 0.12)',
                        focus: 'rgba(0, 0, 0, 0.12)',
                        disabled: 'rgba(0, 0, 0, 0.38)',
                        active: 'rgba(0, 0, 0, 0.56)'
                    },
                    info: {
                        main: 'rgba(33, 150, 243, 1)',
                        dark: 'rgba(11, 121, 208, 1)',
                        light: 'rgba(100, 182, 247, 1)',
                        contrastText: 'rgba(255, 255, 255, 1)'
                    },
                    error: {
                        main: 'rgba(244, 67, 54, 1)',
                        dark: 'rgba(227, 27, 12, 1)',
                        light: 'rgba(248, 128, 120, 1)',
                        contrastText: 'rgba(255, 255, 255, 1)'
                    },
                    warning: {
                        main: 'rgba(255, 152, 0, 1)',
                        dark: 'rgba(199, 119, 0, 1)',
                        light: 'rgba(255, 181, 71, 1)',
                        contrastText: 'rgba(0, 0, 0, 0.87)'
                    },
                    success: {
                        main: 'rgba(76, 175, 80, 1)',
                        dark: 'rgba(59, 135, 62, 1)',
                        light: 'rgba(123, 198, 126, 1)',
                        contrastText: 'rgba(255, 255, 255, 1)'
                    },
                    background: {
                        default: 'rgba(255, 255, 255, 1)',
                        paper: 'rgba(255, 255, 255, 1)'
                    },
                    divider: 'rgba(0, 0, 0, 0.12)'
                }
            },
            locale
        )

        getTheme = () => theme

        return theme
    }

    return getTheme
})()
