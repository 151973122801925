import React from 'react'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { ListItemTextMemo } from 'src/components/general/ListItemText'

import { useChatStore } from '../../chatCommunParts/chat-store'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useApplicationStore } from 'src/aplication-store'
import { getOrMakeHeaderStyles } from './Mobile.styles'

// :: Component(ChatHeaderView)

export function ChatHeaderView() {
    const scope = useChatStore((store) => store.header)
    const fullpage = useApplicationStore((store) => store.fullPage)

    const { classes } = getOrMakeHeaderStyles()

    return (
        <div className={classes.view}>
            {!fullpage && (
                <IconButton
                    edge="start"
                    className={classes.appBarMenuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={scope.onOpenContactList}
                >
                    <ArrowBackIcon />
                </IconButton>
            )}
            <ListItem>
                <ListItemAvatar>
                    <Avatar>{scope.initials}</Avatar>
                </ListItemAvatar>
                <ListItemTextMemo primary={scope.name} secondary={''}></ListItemTextMemo>
            </ListItem>
        </div>
    )
}
