import React from 'react'

import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import { IViewProps } from 'wdc-cube-react'
import { ChatContactScope } from '../../chatCommunParts/chat-scope'
import { getOrMakeStyles } from './ChatContactItem.styles'
import { TextsProvider } from '../texts'

const texts = TextsProvider.get()

// :: Component(ChatContactItemView)

export type ChatContactItemProps = IViewProps & {
    scope: ChatContactScope
}

export function ChatContactItemView({ scope }: ChatContactItemProps) {
    const { classes } = getOrMakeStyles()

    return (
        <ListItem disablePadding key={scope.id} onClick={scope.onSelect}>
            <ListItemButton disabled={scope.disabled}>
                <ListItemAvatar>
                    <Avatar>{scope.initials}</Avatar>
                </ListItemAvatar>
                <div className={classes.contentView}>
                    <div className={classes.middleContent}>
                        <Typography component="div" className={classes.primaryContent}>
                            {scope.name}
                        </Typography>
                        <Typography
                            component="div"
                            variant="caption"
                            color="text.secondary"
                            className={classes.secondaryContent}
                        >
                            {getDateDescription()}
                        </Typography>
                    </div>
                    <div className={classes.middleContent}>
                        <Typography
                            component="div"
                            variant="subtitle2"
                            color="text.secondary"
                            className={classes.subtitleContent}
                        >
                            {scope.message}
                        </Typography>
                        {scope.quantity > 0 ? (
                            <Chip component="div" size="small" label={`${scope.quantity}`} color="error" />
                        ) : undefined}
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    )

    function getDateDescription() {
        const currentDate = new Date()
        const today = currentDate.toLocaleDateString()
        const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1)).toLocaleDateString()

        if (scope.date === today) {
            return scope.time
        }
        if (scope.date === yesterday) {
            return texts.CHATS_YESTERDAY_LABEL
        }

        return scope.date
    }
}
