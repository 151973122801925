import { green } from '@mui/material/colors'
import { keyframes } from '@mui/system'
import { makeStyles } from 'tss-react/mui'

const ShowKeyFrames = keyframes`
    0% {
        transform: scale(.3);
    }
    50% { 
        transform: scale(1.05);
    }
    70% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
`

const HideKeyFrames = keyframes`
    0% { 
        transform: scale(1);
    }
    50% { 
        transform: scale(.9);
    }
    70% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(.3);
    }
`

export const getOrMakeStyles = makeStyles()(() => ({
    defaultFont: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px'
    },
    view: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        right: 20,
        bottom: 82,
        alignItems: 'stretch',
        zIndex: 1050
    },
    fullView: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100vh'
    },
    hidden: {
        display: 'none'
    },
    visible: {
        zIndex: 10
    },
    showAnimation: {
        animation: `${ShowKeyFrames} 0.5s`
    },
    hideAnimation: {
        animation: `${HideKeyFrames} 0.5s`
    },
    circle: {
        zIndex: 0,
        backgroundColor: green[400],
        ':hover': {
            backgroundColor: green[800],
            transform: 'scale(1.1)',
            transition: 'transform 134ms cubic-bezier(0.45, 0, 0.2, 1)'
        }
    },
    badge: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        '& svg': {
            color: 'white',
            width: 27,
            height: 27
        },
        '& .MuiBadge-badge': {
            top: 8,
            right: 5,
            zIndex: 1
        }
    },
    contentView: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 600,
        minHeight: 516,
        alignSelf: 'stretch',
        display: 'flex'
    }
}))
