import { StatusType } from './channel'
import { GroupAndTypeInfo } from './commons'
import { ServiceDTO } from '../../../internal-communication'
import { ChannelMenuType } from '../../admin/ChannelConfiguration.communication'

export enum MenuTags {
    UNKNOWN = 'UNKNOWN',
    EVENT = 'EVENT',
    LINK = 'LINK',
    ROBOT = 'ROBOT',
    MENU = 'MENU',
    INFORMATION = 'INFORMATION',
    SEGMENTED = 'SEGMENTED'
}

export type MenuTagsCount = Record<MenuTags, { count: number }>

export type MenuType = Record<number, keyof typeof ChannelMenuType>

export type MenuOptions = { groupsAndTypes: GroupAndTypeInfo; menuTypes: MenuType }

export type MenusResponse = {
    options: MenuOptions
    configured: ConfiguredMenus
    status: MenuStatus
}

export type MenuCompanyInfo = {
    _id: string
    companyId: number
    name: string
    segmentations: string[]
}

export type MenuKey = {
    key?: string
    menuUUID?: string
}

export type MenuDTOGroupAndTypeLabel = { groupLabel: string; typeLabel: string }

export type MenuDTO = MenuKey &
    MenuDTOGroupAndTypeLabel &
    Omit<ServiceDTO, '_id' | 'companyId' | 'createdAt' | 'editedAt' | 'partnerId'>

export type MenuWithKey = MenuKey & ServiceDTO

export type SegmentationConfigured = {
    company: MenuCompanyInfo
    segmentations: string[]
}

export type SegmentationMenus = {
    menu: { _id: string } & Pick<MenuDTO, 'key'>
    configured: SegmentationConfigured
}

export type Menus = {
    companies: MenuCompanyInfo[]
    menus: MenuDTO[]
    tags: MenuTagsCount
    segmentationMenus: SegmentationMenus[]
    errors?: MenuError[]
}

export type ByCompanyMenus = {
    company: MenuCompanyInfo
    menus: ServiceDTO[]
}

export type DraftMenuWithKey = MenuKey & ServiceDTO

export type ByCompanyMenusDraft = {
    company: MenuCompanyInfo
    menus: DraftMenuWithKey[]
}

export type ChannelDraftMenus = ByCompanyMenusDraft[]

export type ConfiguredMenus = {
    menus: Menus[]
    byCompany: ByCompanyMenus[]
}

export type MenuStatus = {
    type: StatusType
    error?: Error
    warning?: {}
}

export type MenuError = BaseMenuError & Error

export type BaseMenuError = { key: string; fatherKey?: string }

export type Error =
    | EventMenuError
    | LinkMenuError
    | RobotMenuError
    | InformationMenuError
    | MenuMenuError
    | CompanyMenuError
    | SegmentationError

export type SegmentationError = {
    key: string
    id: string
    missingSegmentation?: string[]
}

export type EventMenuError = {
    missingMenuInfo: boolean
    menuInfo?: { group?: string; type: string; company: MenuCompanyInfo }[]
}

export type LinkMenuError = { missingLink: boolean }

export type RobotMenuError = { missingSelfScheduleCompanyConfig: boolean; companies: MenuCompanyInfo[] }

export type InformationMenuError = EventMenuError

export type MenuMenuError = { missingChildren: boolean }

export type CompanyMenuError = { missingMenus: boolean; companies: MenuCompanyInfo[] }
